import { Col, Container, Nav, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import styles from "./style.module.scss";

// import handleViewport from "react-in-viewport";
import { parseHtml, toWebp } from "../../../utils/common";

const Process = (props) => {
  const { data } = props;
  const inViewport = true;
  // const { forwardedRef, inViewport, data } = props;
  const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;
  const divRef = useRef(null);
  const [height, setHeight] = useState();

  useEffect(() => {
    if (data) {
      const imageUrl = baseUrl + data?.background?.data?.attributes?.url;
      divRef.current.style.backgroundImage = `url(${imageUrl})`;
    }
  }, [data, baseUrl]);

  useEffect(() => {
    const setView = () => {
      if (window?.innerWidth > 1400) {
        setHeight(700);
      } else {
        setHeight(350);
      }
    };
    setView();

    window.addEventListener("resize", setView);
    return () => {
      window.addEventListener("resize", setView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section ref={divRef} className={styles["process-new-section"]} style={{ minHeight: `${height}px` }}>
      <Container className={styles['container']} /* ref={forwardedRef} */>
        {inViewport && (
          <Row className={styles["card-border-line"]}>
            <Col lg={12}>
              <div className={styles["process-new-content"]}>
                <h2>{data.title}</h2>
              </div>
            </Col>
            {data?.steps &&
              data?.steps.map((step, i) => {
                return (
                  <Col lg={4} md={4} key={i}>
                    <div className={styles["process-new-card"]}>
                      <div className={styles["process-heading"]}>
                        <div className={styles["next-project-left-content"]}>
                          <div className={styles["next-project-profile"]}>
                            <Image
                              src={
                                step?.image?.data?.attributes?.url
                                  ? toWebp(
                                      `${baseUrl}${step?.image?.data?.attributes?.url}`
                                    )
                                  : null
                              }
                              alt={
                                step?.image?.data?.attributes?.name || "Process"
                              }
                              height={50}
                              width={50}
                            />
                          </div>
                          <span></span>
                        </div>
                      </div>
                      <div className={styles["process-body"]}>
                        <h3>{step.title}</h3>

                        {step.description
                          ? parseHtml(step.description, true)
                          : ""}
                      </div>
                      <a
                        className={`btn ${styles["btn-steps"]} ${styles["remove-border"]}`}
                      >
                        <span>{step.numbers}</span>
                      </a>
                    </div>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>
    </section>
  );
};

// const ProcessViewportBlock = handleViewport(Process);

export default Process;
