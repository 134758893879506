import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import styles from './style.module.scss'

const Enterprices = (props) => {
  const { data } = props;

  return (
    <section
      className={styles['enterprices-section']}
    >
        <Container className={styles.container}>
          <Row>
            {data?.statistics &&
              data?.statistics.map((val, i) => {
                return (
                  <Col lg={4} sm={6} xs={12} key={i}>
                    <div
                      className={
                        i < 3
                          ? styles['clientbox-content']
                          : `${styles['clientbox-content']} ${styles['line-green']}`

                      }

                    >
                      <h3>{val.numbers}</h3>
                      <p>{val.title}</p>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
    </section>
  );
};

export default Enterprices;
