import React, { useRef, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import apiservice from "../../../common/services/apiService";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useRouter } from "next/router";
import { PHONE_REGEX, SLIDER_STEPS, parseHtml } from "../../../utils/common";
import { hideFormLoader, showFormLoader } from "../../../actions";
import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import Image from "next/image";
const DynamicSelect = dynamic(() => import("react-select"), { ssr: false });

const HomeRfp = () => {
	const router = useRouter();
	const dispatch = useDispatch();
	const sitekey = process.env.NEXT_PUBLIC_RECAPTCHA_V3;
	const reCaptchaRef = useRef(null);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [selectedBudget, setSelectedBudget] = useState("$5K - $15K");
	const schema = yup
		.object({
			full_name: yup.string().trim().required("Full name is required."),
			country_name: yup.string().required("Country is required."),
			email: yup
				.string()
				.trim()
				.email("Email is not valid.")
				.required("Email is required."),
			contact_on: yup
				.string()
				.nullable()
				.min(10, "Invalid phone number")
				.max(13, "Invalid phone number")
				.matches(PHONE_REGEX, "Invalid phone number"),
			service_type: yup
				.array()
				.test(
					"length",
					"Select at least one type of service",
					(value) => {
						if (!value || value?.length == 0) {
							return false;
						}
						return true;
					}
				),
			project_summary: yup.string().required("Summary is required."),
			project_files: yup
				.mixed()
				.test(
					"type",
					"Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc",
					(value) => {
						let flag = true;
						if (selectedFiles && selectedFiles?.length > 0) {
							flag = false;
							for (let i = 0; i < selectedFiles?.length; i++) {
								if (
									selectedFiles[i]?.type == "image/jpeg" ||
									selectedFiles[i]?.type == "image/bmp" ||
									selectedFiles[i]?.type == "image/png" ||
									selectedFiles[i]?.type ==
										"application/pdf" ||
									selectedFiles[i]?.type ==
										"application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
									selectedFiles[i]?.type ==
										"application/msword"
								) {
									flag = true;
								} else {
									flag = false;
									break;
								}
							}
						}
						return flag;
					}
				),
			project_budget_min: yup.number().positive().integer().required(),
			project_budget_max: yup.number().positive().integer().required(),
		})
		.required();

	// function RequestProposal(props) {
	function valueLabelFormat(value) {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	const [value, setBudgetValue] = useState([500, 150000]);
	const min = 500,
		max = 500000;
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		reset,
	} = useForm({
		mode: "all",
		resolver: yupResolver(schema),
		defaultValues: {
			full_name: "",
			country_name: "",
			email: "",
			contact_on: "",
			service_type: [],
			project_summary: "",
			project_files: "",
			project_budget_min: value[0],
			project_budget_max: value[1],
		},
	});
	const handleChange = (event, newValue) => {
		setBudgetValue(newValue);
		setValue("project_budget_min", newValue[0]);
		setValue("project_budget_max", newValue[1]);
	};
	let getTheme = "light";
	if (typeof window !== "undefined") {
		getTheme = localStorage.getItem("Theme");
	}

	const validateCaptchaV3 = () => {
		return new Promise((resolve, reject) => {
			reCaptchaRef.current.executeAsync().then(async (value) => {
				resolve(value);
				// const captchaRes = await axios.post(`/api/validateCaptchaV3`, {
				// 	token: value,
				// });
				// if (captchaRes.status === 200 && captchaRes.data.success) {
				// 	resolve(true);
				// } else {
				// 	reject(false);
				// }
			});
		});
	};
	const budgetOptions = [
		{ value: "$5K - $15K", label: "$5K - $15K" },
		{ value: "$15K - $50K", label: "$15K - $50K" },
		{ value: "$50K - $100K", label: "$50K - $100K" },
		{ value: "$100K - $300K", label: "$100K - $300K" },
		{ value: "$300K - $500K", label: "$300K - $500K" },
		{ value: "$500K +", label: "$500K +" },
	];
	const handleBudgetChange = (event) => {
		setSelectedBudget(event.target.value);
	};
	const onSubmit = async (formData, e) => {
		const isHuman = await validateCaptchaV3();

		if (!isHuman) {
			return false;
		}
		dispatch(showFormLoader());
		let data = { ...formData };
		data.service_type = data.service_type.toString();

		const form_data = new FormData();
		for (let key in data) {
			if (key === "project_files") {
				selectedFiles.forEach((file) => {
					form_data.append("project_files", file);
				});
			} else {
				form_data.append(key, data[key]);
			}
		}

		form_data.append("captcha_token_v3", isHuman);
		form_data.append("page_link", window.location.href);
		form_data.append("page_title", document.title);
		if (!!sessionStorage.getItem("rfp-referer")) {
			form_data.append(
				"page_referer",
				sessionStorage.getItem("rfp-referer")
			);
		}
		setSelectedFiles([]);
		const lst = await apiservice.multipart_post("proposal", form_data);
		sessionStorage.removeItem("rfp-referer");
		dispatch(hideFormLoader());

		if (lst.status === 200) {
			// e.target.reset();
			reCaptchaRef.current.reset();
			router.push("/thank-you");
		} else {
			toast.error(lst?.data?.message);
		}
		setBudgetValue([500, 150000]);

		reset();
	};

	const removeFile = (index) => {
		const newFiles = [...selectedFiles];
		newFiles.splice(index, 1);
		setSelectedFiles(newFiles);
	};

	const handleFileSelect = (event) => {
		const files = event.target.files;
		const filesArray = Object.values(files);
		setSelectedFiles((prevSelectedFiles) =>
			prevSelectedFiles.concat(filesArray)
		);
	};

	const handleFileDrop = (event) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		const filesArray = Object.values(files);
		setSelectedFiles((prevSelectedFiles) =>
			prevSelectedFiles.concat(filesArray)
		);
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const serviceTypeOptions = [
		{
			value: "Digital Product Design",
			label: "Digital Product Design",
		},
		{
			value: "Dedicated Software Developers",
			label: "Dedicated Software Developers",
		},
		{
			value: "Cloud Engineering & DevOps",
			label: "Cloud Engineering & DevOps",
		},
		{
			value: "Custom Software Development",
			label: "Custom Software Development",
		},
		{
			value: "Mobile App Development",
			label: "Mobile App Development",
		},
		{
			value: "Software Modernization",
			label: "Software Modernization",
		},
		{
			value: "Quality Assurance",
			label: "Quality Assurance",
		},
		{
			value: "Digital Transformation & Innovation",
			label: "Digital Transformation & Innovation",
		},
		{
			value: "Not Sure",
			label: "Not Sure",
		},
	];

	return (
		<>
			<Form
				onSubmit={handleSubmit(onSubmit)}
				className="contact-right-form"
			>
				<div className="request-form">
					<div className="row">
						<Col lg={12} md={12}>
							<h2>Request for Proposal</h2>
						</Col>
						<Col lg={6} md={6}>
							<div className="form-group form-floating">
								<input
									type="name"
									className="form-control"
									name="full_name"
									aria-describedby="emailHelp"
									placeholder="Full Name"
									{...register("full_name")}
								/>
								<label
									htmlFor="full_name"
									className="form-label"
								>
									Full name*
								</label>
								<span
									style={{ fontSize: "12px", color: "red" }}
								>
									{errors.full_name?.message}
								</span>
							</div>
						</Col>
						<Col lg={6} md={6}>
							<div className="form-group form-floating">
								<input
									type="email"
									className="form-control"
									name="email"
									aria-describedby="emailHelp"
									placeholder="Email"
									{...register("email")}
								/>
								<label htmlFor="email" className="form-label">
									Email*
								</label>
								<span
									style={{
										fontSize: "12px",
										color: "red",
									}}
								>
									{errors.email?.message}
								</span>
							</div>
						</Col>
						<Col lg={6} md={6}>
							<div className="form-group form-floating">
								<input
									type="tel"
									className="form-control"
									name="contact_on"
									aria-describedby="emailHelp"
									placeholder="Phone number"
									{...register("contact_on")}
								/>
								<label
									htmlFor="contact_on"
									className="form-label"
								>
									Phone number*
								</label>
								<span
									style={{
										fontSize: "12px",
										color: "red",
									}}
								>
									{errors.contact_on?.message}
								</span>
							</div>
						</Col>
						<Col lg={6} md={6}>
							<div className="form-group form-floating">
								<input
									type="name"
									className="form-control"
									name="country_name"
									aria-describedby="emailHelp"
									placeholder="Country"
									{...register("country_name")}
								/>
								<label
									htmlFor="country_name"
									className="form-label"
								>
									Country*
								</label>
								<span
									style={{
										fontSize: "12px",
										color: "red",
									}}
								>
									{errors.country_name?.message}
								</span>
							</div>
						</Col>
						<Col lg={12} md={12}>
							<div className="form-group form-floating">
								<textarea
									type="text"
									className="text-area form-control"
									name="project_summary"
									id="project_summary"
									aria-describedby="emailHelp"
									{...register("project_summary")}
									placeholder={`Describe your project goals and we'll help you get there.`}
								/>
								<label
									htmlFor="exampleInputEmail1"
									className="form-label"
								>
									Project description*
								</label>
								<span
									style={{
										fontSize: "12px",
										color: "red",
									}}
								>
									{errors.project_summary?.message}
								</span>
							</div>
						</Col>
						<Col lg={12} md={12}>
							<div className="form-group update-bugget-select">
								<DynamicSelect
									placeholder={
										<div className="budget-placeholder">
											Select type of services you require*
										</div>
									}
									isSearchable={false}
									isMulti
									value={serviceTypeOptions.find(
										(c) => c.value === value
									)}
									onChange={(val) => {
										setValue(
											"service_type",
											val.map((el) => el.value)
										);
									}}
									options={serviceTypeOptions}
									className="budget-select"
									classNamePrefix="budget-select"
								/>
								<span
									style={{
										fontSize: "12px",
										color: "red",
									}}
								>
									{errors.service_type?.message}
								</span>
							</div>
						</Col>
						{/* <Col lg={6} md={12}>
              <div className="budget-content bt-bugget-content">
                <div className="form-group select-budget">
                  <div className="range-slider form-control">
                    <h3>Select your budgetss*</h3>

                    <div className="slider-labels">
                      <div className="caption">
                        <p>
                          <em>Min:</em>{" "}
                          <span id="slider-range-value1">
                            ${valueLabelFormat(value[0])}
                          </span>
                        </p>
                        <input
                          type="hidden"
                          id="project_budget_min"
                          name="project_budget_min"
                          {...register("project_budget_min")}
                        />
                      </div>
                      <div className="caption">
                        <p>
                          <em>Max:</em>{" "}
                          <span id="slider-range-value2">
                            ${valueLabelFormat(value[1])}
                          </span>
                        </p>
                        <input
                          type="hidden"
                          id="project_budget_max"
                          name="project_budget_max"
                          {...register("project_budget_max")}
                        />
                      </div>
                    </div>

                    <Box
                      sx={{
                        mx: 1,
                      }}
                    >
                      <Slider
                        step={SLIDER_STEPS}
                        // getAriaLabel={() => "Budget"}
                        value={value}
                        min={min}
                        max={max}
                        onChange={handleChange}
                        valueLabelDisplay="off"
                      // getAriaValueText={valuetext}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </Col> */}

						<Col xl={7} lg={7} md={7}>
							<div className="budget-content">
								<div className="form-group">
									<div className="range-slider">
										<label
											htmlFor="budgetSelect"
											className="form-label"
										>
											Select your budget*
										</label>

										<ul className="pricing-list">
											{budgetOptions.map(
												(option, index) => (
													<li key={index}>
														<input
															type="radio"
															id={`budget-${index}`}
															name="budget"
															value={option.value}
															className="pricing-radio"
															checked={
																selectedBudget ===
																option.value
															} // Check if this option is the selected one
															onChange={
																handleBudgetChange
															}
														/>
														<label
															htmlFor={`budget-${index}`}
															className="pricing-label" //selected-label
														>
															{option.label}
														</label>
													</li>
												)
											)}
										</ul>
									</div>
								</div>
							</div>
						</Col>
						<Col xl={5} lg={5} md={5}>

							<div>
								<div className="select-budget form-group">
									<label
										className="form-control"
										htmlFor="file-input"
									>
										<h3>Upload file*</h3>
										<input
											type="file"
											name="project_files"
											id="file-input"
											className="file-input-budget"
											multiple=""
											{...register("project_files")}
											onChange={handleFileSelect}
										></input>

										<div className="budget-upload-file">
											<div className="upload-icon">
												<Image
													src="/images/home/upload-icon.svg"
													alt="Upload"
													height={40}
													width={40}
												/>
											</div>
											<span>Upload document</span>
										</div>
									</label>
								</div>
								<div className="filelist">
									{selectedFiles?.map((file, index) => (
										<div
											className="filelist-item"
											key={file.name}
										>
											<div className="filelist-name">
												{file.name}
											</div>
											<div
												className="btn-remove"
												onClick={() =>
													removeFile(index)
												}
											>
												X
											</div>
										</div>
									))}
								</div>
								<span
									style={{
										color: "red",
									}}
								>
									{errors.project_files?.message}
								</span>
							</div>
						</Col>
						<Col lg={7}>
							<div className="sign-nda">
								<a href="#">
									<Image
										src="/images/home/tick-circle.svg"
										alt="tick"
										height={20}
										width={20}
									/>
									100% confidential
								</a>
								<a href="#">
									<Image
										src="/images/home/tick-circle.svg"
										alt="tick"
										height={20}
										width={20}
									/>{" "}
									We sign NDA{" "}
								</a>
							</div>
						</Col>
						<Col lg={5}>
							<div className="budget-btn">
								<button
									type="submit"
									value="Submit"
									className="btn btn-primary"
								>
									Submit
								</button>
							</div>
						</Col>
					</div>
				</div>

				<ReCAPTCHA
					style={{ display: "inline" }}
					theme="dark"
					size="invisible"
					ref={reCaptchaRef}
					sitekey={sitekey}
				/>
			</Form>
		</>
	);
};

export default HomeRfp;
