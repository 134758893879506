import { Col, Container, Row } from "react-bootstrap";
// import handleViewport from "react-in-viewport";
import Image from "next/image";
import { toWebp } from "../../../utils/common";
import styles from "./style.module.scss";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Studies = (props) => {
	const { data } = props;
	const inViewport = true;
	// const { forwardedRef, inViewport, data } = props;
	const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;
	const [height, setHeight] = useState();

	// const renderTooltip = (props) => (
	// 	<Tooltip
	// 		className={styles["case-tool-tip"]}
	// 		id="button-tooltip"
	// 		{...props}
	// 	>
	// 		<p>Frontend</p>
	// 		<span>VUE</span>
	// 	</Tooltip>
	// );
	const renderTooltip = (props, title, displayTitle) => (
		<Tooltip className={styles["case-tool-tip"]} id="button-tooltip" {...props}>
		  {title && <p>{title}</p>}
		  {displayTitle && <span>{displayTitle}</span>}
		</Tooltip>
	  );


	useEffect(() => {
		const setView = () => {
			if (window?.innerWidth > 1400) {
				setHeight(1430);
			} else {
				setHeight(900);
			}
		};
		setView();

		window.addEventListener("resize", setView);
		return () => {
			window.addEventListener("resize", setView);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section
			/* ref={forwardedRef} */ className={styles["studies-new-section"]}
			style={{ minHeight: `${height}px` }}
		>
			{inViewport && (
				<Container className={styles["container"]}>
					<Row className="justify-content-center">
						<Col lg={12}>
							<div className={styles["industry-new-content"]}>
								<h2>{data.title}</h2>
							</div>
						</Col>
					</Row>

					{data?.Projects &&
						data?.Projects.map((project, i) => {
							return (
								<div
									className={styles["studie-project-card"]}
									style={{
										background: `${project.bg_color}`,
									}}
									key={i}
								>
									<Row>
										<Col lg={5} md={5}>
											<div
												className={
													styles[
														"project-left-content"
													]
												}
											>
												<div
													className={
														styles[
															"project-top-content"
														]
													}
												>
													<div
														className={
															styles[
																"project-top-logo"
															]
														}
													>
														<Image
															src={
																project?.Logo
																	?.data
																	?.attributes
																	?.url
																	? toWebp(
																			`${baseUrl}${project?.Logo?.data?.attributes?.url}`
																	  )
																	: null
															}
															alt={
																project?.Logo
																	?.data
																	?.attributes
																	?.name ||
																"Studies"
															}
															height={50}
															width={50}
															loading="eager"
															sizes="(max-width: 768px) 100vw, (max-width: 1200px) 40vw, 25vw"
															layout="responsive"
														/>
													</div>
													<div
														className={
															styles[
																"project-heading-text"
															]
														}
													>
														<h2>{project.Title}</h2>
														{/* <ul>
															<li>
																<span>User Retention</span>
																<p>{project.user_retention}</p>
															</li>
															<li>
																<span>Localized Domains</span>
																<p>{project.localized_domain}</p>
															</li>
														</ul> */}

														<div
															className={
																styles[
																	"case-studies-content-right"
																]
															}
														>
															<div className="row g-3">
																{/* {project?.technologies?.[0]?.title && ( */}

																{/* <div className="col-lg-6 col-md-6 col-sm-6 col-6">
																<div className={styles["resources-nu-card"]}>
																	<h4>Resources Utilized</h4>
																	<h3>5-6</h3>
																</div>
															</div>

															<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																<div className={styles["resources-nu-card"]}>
																	<h4>Engagement Period</h4>
																	<h3>1 Year</h3>
																</div>
															</div> */}
																{project?.key_points &&
																	project.key_points.map(
																		(
																			keyPoint,
																			index
																		) => (
																			<div
																				className="col-lg-6 col-md-6 col-sm-6 col-6"
																				key={
																					index
																				}
																			>
																				<div
																					className={
																						styles[
																							"resources-nu-card"
																						]
																					}
																				>
																					{keyPoint.title && (
																						<h4>
																							{
																								keyPoint.title
																							}
																						</h4>
																					)}
																					{keyPoint.numbers && (
																						<h3>
																							{
																								keyPoint.numbers
																							}
																						</h3>
																					)}
																				</div>
																			</div>
																		)
																	)}
																<div className="col-lg-12 col-md-12">
																	{project
																		?.technologies?.[0]
																		?.title && (
																		<div
																			className={
																				styles[
																					"resources-nu-card"
																				]
																			}
																		>
																			<h4>
																				{
																					project
																						.technologies[0]
																						.title
																				}
																			</h4>
																			{/* <h4>Tech Stack Deployed</h4> */}
																			{/* <ul className={`${styles["stack-deployed-div"]} ${styles["justify-content-around"]}`}>
																			{project.technologies[0]?.technologies?.map((tech, index))}
																				<li>
																					<OverlayTrigger
																						placement="top"
																						delay={{ show: 250, hide: 400 }}
																						overlay={renderTooltip}
																					>
																						<div className={styles["stack-deployed-img"]} placement="right">
																							<Image
																								src={"/images/vue.svg"}
																								alt="case-stud"
																								height={50}
																								width={50}
																							/>
																						</div>
																					</OverlayTrigger>
																				</li>

																				<li>
																					<OverlayTrigger
																						placement="top"
																						delay={{ show: 250, hide: 400 }}
																						overlay={renderTooltip}
																					>
																						<div className={styles["stack-deployed-img"]} placement="right">
																							<Image
																								src={"/images/vue.svg"}
																								alt="case-stud"
																								height={50}
																								width={50}
																							/>
																						</div>
																					</OverlayTrigger>
																				</li>

																				<li>
																					<OverlayTrigger
																						placement="top"
																						delay={{ show: 250, hide: 400 }}
																						overlay={renderTooltip}
																					>
																						<div className={styles["stack-deployed-img"]} placement="right">
																							<Image
																								src={"/images/vue.svg"}
																								alt="case-stud"
																								height={50}
																								width={50}
																							/>
																						</div>
																					</OverlayTrigger>
																				</li>
																		</ul> */}
																			<ul
																				className={`${styles["stack-deployed-div"]} ${styles["justify-content-around"]}`}
																			>
																				{project.technologies[0]?.technologies?.map(

																					(
																						tech,
																						index
																					) => (

																						<li
																							key={
																								index
																							}
																						>
																							<OverlayTrigger
																								placement="top"
																								delay={{
																									show: 250,
																									hide: 400,
																								}}
																								overlay={(props) => renderTooltip(props, tech.title, tech.display_title)}
																								// overlay={renderTooltip(
																								// 	tech.title
																								// )} // Pass dynamic title to renderTooltip
																							>
																								<div
																									className={
																										styles[
																											"stack-deployed-img"
																										]
																									}
																								>
																									<Image
																										src={
																											tech
																												?.image
																												?.data
																												?.attributes
																												?.url
																												? toWebp(
																														`${baseUrl}${tech.image.data.attributes.url}`
																												  )
																												: "/images/placeholder.svg" // Fallback image
																										}
																										// alt={
																										// 	tech.title ||
																										// 	"Technology"
																										// }
																										alt={tech.title || tech.display_title || "Technology"}
																										height={
																											50
																										}
																										width={
																											50
																										}
																									/>
																								</div>
																							</OverlayTrigger>
																						</li>
																					)
																				)}
																			</ul>
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
												<a
													href={project.link.href}
													className={
														styles[
															"case-study-link"
														]
													}
												>
													{project.link.label}
													<span>
														<Image
															src="/images/home/skype-right-arrow-light.svg"
															alt="case-study-link"
															height={20}
															width={20}
															loading="eager"
															// sizes="(max-width: 768px) 100vw, (max-width: 1200px) 40vw, 25vw"
															// layout="responsive"
														/>
													</span>
												</a>
											</div>
										</Col>
										<Col lg={7} md={7} className="">
											<div
												className={
													styles[
														"project-right-content"
													]
												}
											>
												<Image
													src={
														project?.Snapshots?.data
															?.attributes?.url
															? toWebp(
																	`${baseUrl}${project?.Snapshots?.data?.attributes?.url}`
															  )
															: null
													}
													alt={
														project?.Snapshots?.data
															?.attributes
															?.name || "Studies"
													}
													height={500}
													width={500}
													loading="eager"
													sizes="(max-width: 768px) 100vw, (max-width: 1200px) 40vw, 25vw"
													layout="responsive"
												/>
											</div>
										</Col>
									</Row>
								</div>
							);
						})}

					<Row>
						<Col lg={12}>
							<div
								className="mt-3 view-all"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItem: "center",
								}}
							>
								<Link
									href={"/case-studies"}
									className="btn btn-primary"
								>
									View All Case Studies
								</Link>
							</div>
						</Col>
					</Row>
				</Container>
			)}
		</section>
	);
};

// const StudiesViewportBlock = handleViewport(Studies);

export default Studies;
