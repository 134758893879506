import React from "react";
import Image from "next/image";
import styles from "./style.module.scss";
import { toWebp } from "../../../utils/common";

const DeveloperWeb = (props) => {
  const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;
  const { data } = props;

  return (
	<>
    <div className={styles["developer-table"]}>
      {data?.table_rows &&
        data?.table_rows.map((table, i) => {
			if(table.title !== null){
				return (

					<ul
					  className={
						table.title === "Key Points"
						  ? `${styles["developer-table-list"]} ${
							  i % 2 !== 0 && table.title !== null
								? styles["developer-bg-list"]
								: ""
							}`
						  : `${styles["developer-inline-list"]} ${
							  i % 2 !== 0 && table.title !== null
								? styles["developer-bg-list"]
								: ""
							}`
					  }
					  key={i}
					>
					  <li>{table.title}</li>
					  {table?.points &&
						table?.points.map((point, ind) => {
						  return (
							<React.Fragment key={`${ind}_f`}>
							  {table.title === "Key Points" ? (
								<li
								  className={
									ind % 2 === 0
									  ? styles["developer-list-blue"]
									  : styles["developer-list-green"]
								  }
								>
								  <div className={styles["developer-list-box"]}>
									<div className={styles["next-project-profile"]}>
									  <Image
										src={
										  point?.image?.data?.attributes?.url
											? toWebp(
												`${baseUrl}${point?.image?.data?.attributes?.url}`
											  )
											: null
										}
										alt={
										  point?.image?.data?.attributes?.name ||
										  "project-img"
										}
										height={50}
										width={50}
									  />
									</div>
									<span></span>
								  </div>
								  {point.data}
								</li>
							  ) : table.title !== null && (
								<li>{point.data}</li>
							  )}
							</React.Fragment>
						  );
						})}


					</ul>

				  );
			}

        })}

    </div>
	{/* Render a separate standalone button */}
	{`data?.button` && (
        <div
          className={styles["developer-button-container"]}
          style={{
            marginTop: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() =>
              data.button.href
                ? (window.location.href = data.button.href)
                : (window.location.href = "#")
            }
            className="btn btn-primary"
          >
            {data.button.label || "Default Button"}
          </button>
        </div>
      )}

	</>
  );
};

export default DeveloperWeb;
