import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
// import handleViewport from "react-in-viewport";
import Image from "next/image";
import { toWebp } from "../../../utils/common";
import styles from "./style.module.scss";

const Brand = (props) => {
  const { data } = props;
  const inViewport = true;

  const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

  return (
    <section
      // ref={forwardedRef}
      className={styles["brand-section"]}
    //   style={{ minHeight: `${height}px` }}
    >
      {inViewport && (
        <Container className={styles['container']}>
          <Row>
            <Col lg={12}>
              <div className={styles["brand-content"]}>
                <h2>{data.title}</h2>
              </div>
            </Col>
            <Col lg={12}>
              <Row className={styles["clientbox-content"]}>
                {data?.logos?.data &&
                  data?.logos?.data.map((logo, i) => {
					const logoSrc = logo?.attributes?.url
                    ? toWebp(`${baseUrl}${logo?.attributes?.url}`)
                    : null;
					return (
                      <Col key={i} lg={3} md={3} sm={4} xs={4}>
                        <div className={styles["brand-compeny-card"]}>
                          <Image
                            height={100}
                            width={100}
							layout="fixed"
							loading="lazy"
                            alt={logo?.attributes?.name || "Brand"}
                            // src={
                            //   logo?.attributes?.url
                            //     ? toWebp(`${baseUrl}${logo?.attributes?.url}`)
                            //     : null
                            // }
							src = {logoSrc}
                          />
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};
// const BrandViewportBlock = handleViewport(Brand);

export default Brand;
