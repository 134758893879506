import dynamic from "next/dynamic";

import React from "react";
// import Banner from "./banner/banner";
// import Enterprices from "./enterprices-experiance/enterprices-experiance";
// import HocHead from "../HigherOrderComponent/HocHead";
// const Banner = dynamic(() => import("./banner/banner"));
// import ContactCase from "./Contact-Case-Study/contact-Study-case";

const HocHead = dynamic(() => import('../HigherOrderComponent/HocHead'))
// const FullPageLoader = dynamic(() => import('../FullPageLoader/FullPageLoader'))
const Banner = dynamic(() => import("./banner/banner"));
const Enterprices = dynamic(
	() => import("./enterprices-experiance/enterprices-experiance"),
);
const Award = dynamic(() => import("./award-section/award"));
const Feature = dynamic(() => import("./feature/feature"));
const Faq = dynamic(() => import("./faq/faq"));
const Contact = dynamic(() => import("./contact-section/contact"));
const Brand = dynamic(() => import("./brand-section/brand"));
const Industry = dynamic(() => import("./industry/industry-new"));
const Studies = dynamic(() => import("./studies/studies-new"));
const NextProject = dynamic(() => import("./next-project/nextProject"));
const Process = dynamic(() => import("./process-section/process"));
const Customer = dynamic(() => import("./customer-section/customer"));
const Developer = dynamic(() => import("./developer-section/developer"));
const ContactCase = dynamic(() => import("./Contact-Case-Study/contact-Study-case"));

const HomeNew = ({ blocks, seo }) => {
	return (
		<>
			{seo && <HocHead seo={seo} />}

			{/* {loading ? <FullPageLoader /> : ""} */}
			<div className="nome-new">
				{blocks?.length > 0 &&
					blocks.map((block, i) => {
						return (
							<React.Fragment key={i}>
								{block.__component ===
									"blocks.home-new-banner" && (
									<Banner data={block} />
								)}

								{block.__component === "blocks.stats" && (
									<Enterprices data={block} />
								)}

								{block.__component ===
									"blocks.preference-for-enterprises" && (
									<Brand data={block} />
								)}

								{block.__component ===
									"blocks.case-study-key-point-blocks" && (
									<Industry data={block} />
								)}

								{block.__component ===
									"blocks.projects-new" && (
									<Studies data={block} />
								)}

								{block.__component === "blocks.strategies" && (
									<NextProject data={block} />
								)}

								{block.__component ===
									"blocks.home-new-development-process" && (
									<Process data={block} />
								)}

								{block.__component ===
									"blocks.testimonials" && (
									<Customer data={block} />
								)}

								{block.__component ===
									"blocks.hiring-modal" && (
									<Developer data={block} />
								)}

								{block.__component === "blocks.faq" && (
									<Faq data={block} />
								)}

								{/* No data Available */}
								{block.__component ===
									"blocks.awards-and-acolades" && (
									<Award data={block} />
								)}
								{block.__component === "blocks.top-stories" && (
									<Feature data={block} />
								)}

								{block.__component === "blocks.contact-us-case-study" && (
									<ContactCase data={block} />
								)}
								{block.__component ===
									"blocks.home-new-form" && (
									<Contact data={block} />
								)}
							</React.Fragment>
						);
					})}
			</div>
		</>
	);
};

export default HomeNew;
