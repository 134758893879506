import axios from "axios";

const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_API_BASE_URL;
const postbaseUrl = baseUrl;

class ApiService {
    getToken = () => "";

    getapi1 = async (route) => await axios.get(baseUrl + route);

    getapi = (route) => new Promise((resolve, reject) => {
		axios.get(baseUrl + route).then(response => {                
            resolve(response);
        }).catch(({ response }) => {
            if (response && response.status === 400) {
                resolve(response);
            }
            reject(response);
        });
    });

    authGet = (route, params = {}) => new Promise((resolve, reject) => {
        axios.get(baseUrl + route, { params }).then(response => {
            resolve(response);
        }).catch(({ response }) => {
            if (response && response.status === 400) {
                resolve(response);
            }
            reject(response);
        });
    });

    get = (route) => new Promise((resolve, reject) => {
        axios.get(baseUrl + route, {
            headers: {
                Authorization: `Token ${  this.getToken()}`,
            }
        }).then(response => {
            resolve(response);
        }).catch(({ response }) => {
            if (response && response.status === 400) {
                resolve(response);
            }
            if (response && response.status === 401) {
                localStorage.clear();
                window.location.assign("/login");
                reject(response);
            }
            reject(response);
        });
    });

    authPost = (route, params) => new Promise((resolve, reject) => {
        axios.post(baseUrl + route, params).then(response => {
            resolve(response);
        }).catch(({ response }) => {

            if (response && response.status === 400) {
                resolve(response);
            }
            if (response && response.status === 401) {
                localStorage.clear();
                window.location.assign("/login");
                reject(response);
            }
            resolve(response.data);
        });
    })

    post = (route, params) => new Promise((resolve, reject) => {
        axios.post(baseUrl + route, params, {
            headers: {
                Authorization: `Token ${  this.getToken()}`,
            }
        }).then(response => {
            resolve(response);
        }).catch(({ response }) => {
            if (response && response.status === 400) {
                resolve(response);
            }
            if (response && response.status === 401) {
                localStorage.clear();
                window.location.assign("/login");
                reject(response);
            }
        });
    })

	multipart_post = (route, params) => new Promise((resolve, reject) => {
        axios.post(postbaseUrl + route,params,{
            headers: { 
                "Content-Type": `multipart/form-data; boundary=${params._boundary}`,
            }
        }).then(function (response) {
            	resolve(response);
        }).catch(function (response) {
            if (response && response.status === 400) {
                resolve(response);
            } else {
                reject(response);
            } 
        });
    })

    put = (route, params) => new Promise((resolve, reject) => {
        axios.put(baseUrl + route, params, {
            headers: {
                Authorization: `Token ${  this.getToken()}`,
            }
        }).then(response => {
            resolve(response);
        }).catch(({ response }) => {
            if (response && response.status === 400) {
                resolve(response);
            }
            if (response && response.status === 401) {
                localStorage.clear();
                window.location.assign("/login");
                reject(response);
            }
        });
    })

    delete = (route) => new Promise((resolve, reject) => {
        axios.delete(baseUrl + route, {
            headers: {
                Authorization: `Token ${  this.getToken()}`,
            }
        }).then(response => {
            resolve(response);
        }).catch(({ response }) => {
            if (response && (response.status === 400 || response.status === 403)) {
                resolve(response);
            }
            if (response && response.status === 401) {
                localStorage.clear();
                window.location.assign("/login");
                reject(response);
            }
            reject(response);
        });
    })

    patch = (route, params) => new Promise((resolve, reject) => {
        axios.patch(baseUrl + route, params, {
            headers: {
                Authorization: `Token ${  this.getToken()}`,
            }
        }).then(response => {
            resolve(response);
        }).catch(({ response }) => {
            if (response.status === 400) {
                resolve(response);
            }
            if (response.status === 401) {
                localStorage.clear();
                window.location.assign("/login");
                reject(response);
            }
            resolve(response);
        });
    })
}

const instance = new ApiService();
export default instance;
