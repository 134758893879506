import React, { useEffect, useRef, useState } from "react";
import Style from "./style.module.scss";
const TruncatedText = ({ text, activeTab }) => {
	const [showFullText, setShowFullText] = useState(false);
	const [isTruncated, setIsTruncated] = useState(false);
	const contentRef = useRef(null);
	const maxLines = 4;

	const handleToggle = () => {
		console.log("Button clicked");
		setShowFullText((prev) => {

			const newState = !prev;

			return newState;
		});
	};
	useEffect(() => {
		const timeout = setTimeout(() => {
			checkTruncation();
		}, 0);

		return () => clearTimeout(timeout);
	}, [text, activeTab]);

	const checkTruncation = () => {
		if (contentRef.current) {
			const prevDisplay = contentRef.current.style.display;
			contentRef.current.style.display = "block";

			const lineHeight = parseFloat(
				getComputedStyle(contentRef.current).lineHeight || "1.5",
			);
			const maxHeight = lineHeight * maxLines;



			const isOverflowing = contentRef.current.scrollHeight > maxHeight;

			setIsTruncated(isOverflowing);

			contentRef.current.style.display = prevDisplay;
		}
	};

	return (
		<div style={{ position: "relative", lineHeight: "1.5" }}>
			<div
				ref={contentRef}
				className="truncated-text"
				style={{
					display: showFullText ? "block" : "-webkit-box",
					WebkitBoxOrient: "vertical",
					WebkitLineClamp: showFullText ? "none" : maxLines,
					overflow: "hidden",
					textOverflow: "ellipsis",
					textAlign: "left",
					width: "100%",
				}}
				dangerouslySetInnerHTML={{ __html: text }}
			></div>
			{isTruncated && (
				<button className={Style['btn-read-more']}
					onClick={handleToggle}
					style={{
						marginTop: "-2px",
						background: "none",
						border: "none",
						color: "#007bff",

						cursor: "pointer",
					}}
				>
					{showFullText ? "Read Less" : "Read More"}
				</button>
			)}
		</div>
	);
};

export default TruncatedText;
