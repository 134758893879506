import react, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
// import pic from "../../../assets/images/home/compeny-logo.svg";
import Accordion from "react-bootstrap/Accordion";
// import handleViewport from "react-in-viewport";
import { parseHtml } from "../../../utils/common";
import React from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import striptags from "striptags";

function addFaqJson(faqs) {
	let json = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": []
	};

	faqs?.faq.map((faq, index) => {
		json.mainEntity.push(
			{
				"@type": "Question",
				"name": faq.question,
				"acceptedAnswer": {
					"@type": "Answer",
					"text": striptags(faq.answer)
				}
			}
		)
	})

	return {
		__html: `${JSON.stringify(json, null, 2)}`,
	};
}

const Faq = (props) => {
	const router = useRouter()
	const isTechnologyPage = router.route.includes("/technologies/");
	const { data } = props;
	const inViewport = true;
	// const { forwardedRef, inViewport, data } = props;

	const [activeindex, setactiveindex] = useState();
	const [height, setHeight] = useState();

	const handleClick = (index) => {
		if (index == activeindex) {
			setactiveindex(null);
		} else {
			setactiveindex(index);
		}
	};

	useEffect(() => {
		const setView = () => {
			if (window?.innerWidth > 996) {
				setHeight(630);
			} else {
				setHeight(628);
			}
		};
		setView();

		window.addEventListener("resize", setView);
		return () => {
			window.addEventListener("resize", setView);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<Head>
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={addFaqJson(data)}
					key="faq-jsonld"
				/>
			</Head>
			<section
				// ref={forwardedRef}
				className={`faq-new-section ${isTechnologyPage && 'technology-faq-section'}`}
				style={{ minHeight: `${height}px` }}
			>
				{inViewport && (
					<Container className="container">
						<Row>
							<Col lg={12}>
								<div className="faq-new-content">
									<h2>{data.title}</h2>
								</div>
							</Col>
							<Col lg={12}>
								<Accordion defaultActiveKey="0">
									<Row>
										<Col lg={6} md={6}>
											{data?.faq &&
												data?.faq.map((val, i) => {
													return (
														<React.Fragment key={i}>
															{i % 2 === 0 && (
																<Accordion.Item
																	eventKey={i}
																	className={activeindex === i ? "active" : null}
																>
																	<Accordion.Header
																		onClick={() => handleClick(i)}
																	>
																		<span>{val.question}</span>
																	</Accordion.Header>
																	<Accordion.Body>
																		{/* <span>{val.answer}</span> */}
																		{val.answer
																			? parseHtml(val.answer, true)
																			: ""}
																	</Accordion.Body>
																</Accordion.Item>
															)}
														</React.Fragment>
													);
												})}
										</Col>
										<Col lg={6} md={6}>
											{data?.faq &&
												data?.faq.map((val, i) => {
													return (
														<React.Fragment key={i}>
															{i % 2 !== 0 && (
																<Accordion.Item
																	eventKey={i}
																	className={activeindex === i ? "active" : null}
																>
																	<Accordion.Header
																		onClick={() => handleClick(i)}
																	>
																		<span>{val.question}</span>
																	</Accordion.Header>
																	<Accordion.Body>
																		{/* <span>{val.answer}</span> */}
																		{val.answer
																			? parseHtml(val.answer, true)
																			: ""}
																	</Accordion.Body>
																</Accordion.Item>
															)}
														</React.Fragment>
													);
												})}
										</Col>
									</Row>
								</Accordion>
							</Col>
						</Row>
					</Container>
				)}
			</section>
		</>
	);
};

// const FaqViewportBlock = handleViewport(Faq);

export default Faq;
