import { Col, Container, Row } from "react-bootstrap";
import HomeRfp from "./homeRfp";
import Link from "next/link";
import Image from "next/image";
// import handleViewport from "react-in-viewport";
import { toWebp } from "../../../utils/common";
import { useEffect, useState } from "react";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Contact = (props) => {
  const { data } = props;
  const inViewport = true;
  // const { forwardedRef, inViewport, data } = props;
  const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;
  const [height, setHeight] = useState();

  useEffect(() => {
    const setView = () => {
      if (window?.innerWidth > 1400) {
        setHeight(958);
      } else {
        setHeight(790);
      }
    };
    setView();

    window.addEventListener("resize", setView);
    return () => {
      window.addEventListener("resize", setView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="contact-new-section" /* ref={forwardedRef} */ style={{ minHeight: `${height}px` }}>
      {inViewport && (
        <Container>
          <Row>
            <Col lg={5} md={5}>
              <div className="contact-left-content">
                <Link href="#" className="contact-logo">
                  <Image
                    src={
                      data?.logo?.data?.attributes?.url
                        ? toWebp(
                            `${baseUrl}${data?.logo?.data?.attributes?.url}`
                          )
                        : null
                    }
                    alt={data?.logo?.data?.attributes?.name || "Logo"}
                    height={200}
                    width={200}
                    loading="eager"
                    // sizes="(max-width: 768px) 100vw, (max-width: 1200px) 25vw, 25vw"
                    // layout="responsive"
                  />
                </Link>
                <h2>{data.title}</h2>
                <p>{data.short_title}</p>
                <ul>
                  {data?.buttons &&
                    data?.buttons?.map((btn, i) => {
                      return (
                        <li key={i}>
                          <Link href={btn.href}>
                            <div className="call-icon">
                              <Image
                                src={
                                  btn?.image?.data?.attributes?.url
                                    ? toWebp(
                                        `${baseUrl}${btn?.image?.data?.attributes?.url}`
                                      )
                                    : null
                                }
                                alt={
                                  btn?.image?.data?.attributes?.name || "Logo"
                                }
                                height={30}
                                width={30}
                              />
                            </div>
                            {btn.label}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </Col>
            <Col lg={7} md={7}>
              <HomeRfp />
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

// const ContactViewportBlock = handleViewport(Contact);

export default Contact;
