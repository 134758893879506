import Dropdown from "react-bootstrap/Dropdown";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/image";

// Import Swiper styles
import "swiper/css";
// import 'swiper/css/pagination';
import { Pagination } from "swiper";
import { parseHtml, toWebp } from "../../../utils/common";
import styles from "./style.module.scss";

const IndustryMobile = (props) => {
  const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

  const { data } = props;

  const [selectedDropdownIndex, setSelectedDropdownIndex] = useState(0);
  const [selectedDropdownTitle, setSelectedDropdownTitle] = useState(
    data?.Keypoint[0].title
  );

  const handleDropdownItemClick = (index, title) => {
    setSelectedDropdownIndex(index);
    setSelectedDropdownTitle(title);
  };

  return (
    <div>
      <Dropdown className={styles["industry-sm-dropdown"]}>
        <Dropdown.Toggle
          className={styles["dropdown-toggle"]}
          variant="success"
          id="dropdown-basic"
        >
        <span>  {selectedDropdownTitle} </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className={styles["dropdown-menu"]}>
          {data?.Keypoint &&
            data?.Keypoint.map((key, i) => {
              return (
                <Dropdown.Item
                  className={styles["dropdown-item"]}
                  href="#"
                  key={`${i}_h`}
                  onClick={() => handleDropdownItemClick(i, key?.title)}
                >
                  {key?.title}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>

      <div className={styles["indutry-tab-content"]}>
        <Swiper
          slidesPerView="1"
          initialSlide={selectedDropdownIndex || 1}
          loop={true}
          pagination={{
            clickable: true,
          }}
          spaceBetween={30}
          autoplay={4000}
          modules={[Pagination]}
          className={styles["mySwiper swiper"]}
        >
          {data?.Keypoint &&
            data?.Keypoint.map((key, i) => {
              return (
                <React.Fragment key={`${i}_l`}>
                  {selectedDropdownTitle === key.title &&
                    key?.points &&
                    key?.points.map((poi, ind) => {
                      return (
                        <SwiperSlide
                          key={`${ind}_k`}
                          className={styles["swiper-slide"]}
                        >
                          <div
                            className={`${styles["industry-right-content"]} ${styles["active"]}`}
                          >
                            <div className={styles["industry-rt-img"]}>
                              {poi?.image?.data?.attributes?.url && (
                                <Image
                                  src={
                                    poi?.image?.data?.attributes?.url
                                      ? toWebp(
                                          `${baseUrl}${poi?.image?.data?.attributes?.url}`
                                        )
                                      : null
                                  }
                                  className={styles["solution-card"]}
                                  alt={
                                    poi?.image?.data?.attributes?.name ||
                                    "industry"
                                  }
                                  height={200}
                                  width={200}
                                />
                              )}
                            </div>
                            <div className={styles["industry-lt-content"]}>
                              <h4>{poi.title}</h4>
                            </div>
                            <div className={styles["industry-ct-content"]}>
                              {poi?.text ? parseHtml(poi.text, true) : ""}
                              {poi.button && (
                                <a href="#" className="btn btn-primary">
                                  {poi.button.label}
                                </a>
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </React.Fragment>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default IndustryMobile;
