import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
// import handleViewport from "react-in-viewport";
import IndustryWeb from "./industryWeb";
import IndustryMobile from "./industryMobile";
import styles from "./style.module.scss";

const Industry = (props) => {
  const { data } = props;
  const inViewport = true;
  // const { forwardedRef, inViewport, data } = props;
  const [isDesktopView, setIsDesktopView] = useState(true);

  useEffect(() => {
    const setView = () => {
      if (window?.innerWidth > 767) {
        setIsDesktopView(true);
      } else {
        setIsDesktopView(false);
      }
    };
    setView();

    window.addEventListener("resize", setView);
    return () => {
      window.addEventListener("resize", setView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section /* ref={forwardedRef} */ className={styles["industry-new-section"]} style={{ minHeight: "450px" }}>
      {inViewport && (
        <div className={styles["industry-new-inner"]}>
          <Container className={styles["container"]}>
            <Row className="justify-content-center">
              <Col xxl={8} xl={11} lg={10}>
                <div className={styles["industry-new-content"]}>
                  <h2>{data.title}</h2>
                </div>
              </Col>
            </Row>
            {isDesktopView ? (
              <IndustryWeb data={data} />
            ) : (
              <IndustryMobile data={data} />
            )}
          </Container>
        </div>
      )}
    </section>
  );
};

// const IndustryViewportBlock = handleViewport(Industry);

export default Industry;
