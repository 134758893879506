import { Col, Container, Nav, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/image";
import styles from "./style.module.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import React, { useEffect, useState } from "react";
// import handleViewport from "react-in-viewport";
import { useRouter } from "next/router";
// import required modules
const Customer = (props) => {
  const router = useRouter();
  const isTechnologyPage = router.route.includes("/technologies/");
  const { data } = props;
  const inViewport = true;
  // const { forwardedRef, inViewport, data } = props;
  const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;
  const [imageUrl, setImageUrl] = useState("");
  const [height, setHeight] = useState();
  useEffect(() => {
    let imageUrlTest = baseUrl + data?.background_image?.data?.attributes?.url;
    setImageUrl(imageUrlTest);
  }, [data, baseUrl]);
  useEffect(() => {
    const setView = () => {
      if (window?.innerWidth > 1400) {
        setHeight(850);
      } else if (window?.innerWidth < 996 && window?.innerWidth > 768) {
        setHeight(980);
      } else {
        setHeight(500);
      }
    };
    setView();
    window.addEventListener("resize", setView);
    return () => {
      window.addEventListener("resize", setView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section
      // ref={forwardedRef}
      className={`${styles["customer-new-section"]} ${isTechnologyPage && styles["tech-customer-section"]
        }`}
      style={{ minHeight: `${height}px` }}
    >
      {inViewport && (
        <Container className={styles["container"]}>
          <Row>
            <Col lg={12}>
              <div className={styles["customer-content"]}>
                <h2>{data.title}</h2>
                <p>{data.short_title}</p>
              </div>
            </Col>
            <Col lg={12}>
              <div
                className={`${styles["customer-right-content"]} ${styles["swiper-2"]}`}
              // style={{ backgroundImage: `url(${imageUrl})` }}
              >
                {data?.testimonials?.data?.length > 0 && (

                  <Swiper
                    id="home-swiper"
                    slidesPerView={1.68}
                    spaceBetween={20}
                    direction={"horizontal"}
                    loop={true}
                    autoplay={{ delay: 4000 }}
                    navigation={true}
                    className={`${styles.swiper}`}
                    centeredSlides={true}
                    centeredSlidesBounds={true}
                    breakpoints={{
                      768: {
                        slidesPerView: 1.68,
                        spaceBetween: 20,
                        direction: "horizontal",
                      },
                      767: {
                        slidesPerView: 1.68,
                        spaceBetween: 20,
                        direction: "horizontal",
                        centeredSlides: false,
                      },

                      320: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        direction: "horizontal",
                        centeredSlides: false,
                      },
                    }}
                    pagination={{
                      clickable: true,
                      centeredSlides: true,
                    }}
                    modules={[Navigation]}
                  >
                    {data?.testimonials?.data &&
                      data?.testimonials?.data?.map((value, i) => {
                        let test = value.attributes;
                        const starArray = Array.from(
                          { length: test.Rating },
                          (_, index) => index + 1
                        );
                        return (

                          <SwiperSlide
                            className={styles["swiper-slide"]}
                            key={i}
                          >

                            <div className={styles["testi-box"]}>
                              <div className={styles["slider-heading-right"]}>
                                <Image
                                  alt="logo Icon"
                                  src="/images/quote.svg"
                                  height={48}
                                  width={66}
                                />
                              </div>
                              <div className={styles["swiper-heading-text"]}>

                                <div>
                                  <ul>
                                    {starArray?.map((star, ind) => {
                                      return (
                                        <li key={`${ind}_j`}>
                                          <Image
                                            alt="star"
                                            src="/images/home/star-slider.svg"
                                            height={20}
                                            width={20}
                                          />
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <h3>{test.reviewTitle}</h3>

                                </div>

                              </div>
                              <p>{test.text}</p>
                              <h3 className={styles["author"]}>
                                {test.author}

                              </h3>
                              <div className={styles["author-position"]}>{test.AutherPosition}</div>

                            </div>

                          </SwiperSlide>
                        );
                      })}
                  </Swiper>

                )}
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};
// const CustomerViewportBlock = handleViewport(Customer);
export default Customer;

