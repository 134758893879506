import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import handleViewport from "react-in-viewport";
import DeveloperWeb from "./developerWeb";
import DeveloperMobile from "./developerMobile";

import styles from "./style.module.scss";

const Developer = (props) => {
  const { data } = props;
  const inViewport = true;
  // const { forwardedRef, inViewport, data } = props;
  const [isDesktopView, setIsDesktopView] = useState(true);
  const [height, setHeight] = useState();

  useEffect(() => {
    const setView = () => {
      if (window?.innerWidth > 767) {
        setIsDesktopView(true);
      } else {
        setIsDesktopView(false);
      }
      if (window?.innerWidth > 996) {
        setHeight(800);
      } else {
        setHeight(663);
      }
    };
    setView();

    window.addEventListener("resize", setView);
    return () => {
      window.addEventListener("resize", setView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      // ref={forwardedRef}
      className={styles["developer-new-section"]}
      style={{ minHeight: `${height}px` }}
    >
      {inViewport && (
        <Container className={styles['container']}>
          <Row className="justify-content-center">
            <Col lg={8} md={10}>
              <div className={styles["developer-heading-content"]}>
                <h2>{data.title}</h2>
              </div>
            </Col>
          </Row>
          {isDesktopView ? (
            <DeveloperWeb data={data} />
          ) : (
            <DeveloperMobile data={data} />
          )}
        </Container>
      )}
    </section>
  );
};

// const DeveloperViewportBlock = handleViewport(Developer);

export default Developer;
