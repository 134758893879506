// import React, { useEffect, useRef, useState } from "react";
// import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
// // import handleViewport from "react-in-viewport";
// import Image from "next/image";
// import Link from "next/link";
// import { toWebp } from "../../../utils/common";
// import styles from "./style.module.scss";

// // import pic from "../../../assets/images/home/compeny-logo.svg";
// const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

// const Award = (props) => {
//   // const { forwardedRef, inViewport } = props;
//   const inViewport = true;
//   const data = props.data;
//   const divRef = useRef(null);
//   const [height, setHeight] = useState();

//   useEffect(() => {
//     const setView = () => {
//       if (window?.innerWidth > 1400) {
//         setHeight(825);
//       } else {
//         setHeight(588);
//       }
//     };
//     setView();

//     window.addEventListener("resize", setView);
//     return () => {
//       window.addEventListener("resize", setView);
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   useEffect(() => {
//     if (data) {
//       const imageUrl = baseUrl + data?.background?.data?.attributes?.url;
//       divRef.current.style.backgroundImage = `url(${imageUrl})`;
//     }
//   }, [data]);
//   return (
//     <section ref={divRef} className={styles["award-new-section"]} style={{ minHeight: `${height}px` }}>
//       <Container className={styles["container"]} /* ref={forwardedRef} */>
//         {inViewport && (
//           <Row>
//             {data?.awards && (
//               <Col lg={9} md={9} sm={12} xs={12}>
//                 <Row>
//                   {data?.awards &&
//                     data?.awards.map((awd, i) => {
//                       return (
//                         <React.Fragment key={i}>
//                           {i === 4 && (
//                             <Col
//                               lg={8}
//                               md={8}
//                               sm={6}
//                               xs={6}
//                               className={styles['ordersm-01']}
//                             >
//                               <div
//                                 className={`${styles["cirtified-card"]} ${styles["award-card"]}`}
//                               >
//                                 <h3>Awards & Acolades</h3>
//                               </div>
//                             </Col>
//                           )}
//                           <Col lg={4} md={4} sm={6} xs={6}>
//                             <Link href="#">
//                               <div className={styles["cirtified-card"]}>
//                                 {/* <Image
//                                   src={
//                                     awd?.image?.data?.attributes?.url
//                                       ? toWebp(
//                                           `${baseUrl}${awd?.image?.data?.attributes?.url}`
//                                         )
//                                       : null
//                                   }
//                                   alt={
//                                     awd?.image?.data?.attributes?.name ||
//                                     "Awards"
//                                   }
//                                   height={100}
//                                   width={100}
//                                 /> */}
// 								<Image
// 								  src={toWebp(`${baseUrl}${awd?.image?.data?.attributes?.url}`)}
// 								  srcSet={`
// 									${toWebp(`${baseUrl}${awd?.image?.data?.attributes?.url}`)} 1x,
// 									${toWebp(`${baseUrl}${awd?.image?.data?.attributes?.url}`, 90, 200, 200)} 2x
// 								  `}
// 								  alt={awd?.image?.data?.attributes?.name || "Awards"}
// 								  height={100}
// 								  width={100}
// 								  quality={90}
// 								  loading="lazy"
// 								/>
//                               </div>
//                             </Link>
//                           </Col>
//                         </React.Fragment>
//                       );
//                     })}
//                 </Row>
//               </Col>
//             )}

//             <Col lg={3} md={3} sm={12} xs={12}>
//               <Row>
//                 {data?.ratings &&
//                   data?.ratings?.map((rat, index) => {
//                     return (
//                       <Col lg={12} md={12} sm={6} xs={6} key={index}>
//                         <Link href={rat.link || "#"}>
//                           <div
//                             className={`${styles["cirtified-card"]} ${styles["google-card"]} ${styles[`card${index}`]}`}
//                           >
//                             <div className={styles["google-card-heading"]}>
//                               <p>
//                                 <Image
//                                   src="/images/home/base-star.svg"
//                                   alt="star"
//                                   height={20}
//                                   width={20}
//                                 />
//                                 {rat.ratings}
//                               </p>
//                               <Image
//                                 // src={
//                                 //   rat?.provider_image?.data?.attributes?.url
//                                 //     ? toWebp(
//                                 //         `${baseUrl}${rat?.provider_image?.data?.attributes?.url}`
//                                 //       )
//                                 //     : null
//                                 // }
// 								src={toWebp(`${baseUrl}${rat?.provider_image?.data?.attributes?.url}`)}
//                                 alt={
//                                   rat?.provider_image?.data?.attributes?.name ||
//                                   "rating"
//                                 }
//                                 height={40}
//                                 width={150}
//                                 loading="eager"
//                                 // sizes="(max-width: 768px) 100vw, (max-width: 1200px) 40vw, 25vw"
//                                 layout="responsive"
//                               />
//                             </div>
//                           </div>
//                         </Link>
//                       </Col>
//                     );
//                   })}
//               </Row>
//             </Col>
//           </Row>
//         )}
//       </Container>
//     </section>
//   );
// };

// // const AwardViewportBlock = handleViewport(Award);

// export default Award;


// import React, { useEffect, useRef, useState } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import Image from "next/image";
// import Link from "next/link";
// import { toWebp } from "../../../utils/common";
// import styles from "./style.module.scss";

// const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

// const Award = (props) => {
//   const inViewport = true;
//   const data = props.data;
//   const divRef = useRef(null);
//   const [height, setHeight] = useState();

//   useEffect(() => {
//     const setView = () => {
//       if (window?.innerWidth > 1400) {
//         setHeight(825);
//       } else {
//         setHeight(588);
//       }
//     };
//     setView();

//     window.addEventListener("resize", setView);
//     return () => {
//       window.removeEventListener("resize", setView);
//     };
//   }, []);

//   useEffect(() => {
//     if (data) {
//       const imageUrl = baseUrl + data?.background?.data?.attributes?.url;
//       divRef.current.style.backgroundImage = `url(${imageUrl})`;
//     }
//   }, [data]);

//   return (
//     <section ref={divRef} className={styles["award-new-section"]} style={{ minHeight: `${height}px` }}>
//       <Container className={styles["container"]}>
//         {inViewport && (
//           <Row>
//             {data?.awards && (
//               <Col lg={9} md={9} sm={12} xs={12}>
//                 <Row>
//                   {data?.awards.map((awd, i) => {
//                     const awardImageUrl = toWebp(`${baseUrl}${awd?.image?.data?.attributes?.url}`);
//                     const link = awd?.link; // Assume the backend provides a 'link' field for each award

//                     return (
//                       <React.Fragment key={i}>
//                         {i === 4 && (
//                           <Col lg={8} md={8} sm={6} xs={6} className={styles["ordersm-01"]}>
//                             <div className={`${styles["cirtified-card"]} ${styles["award-card"]}`}>
//                               <h3>Awards & Acolades</h3>
//                             </div>
//                           </Col>
//                         )}
//                         <Col lg={4} md={4} sm={6} xs={6}>
//                           {link ? (
//                             <Link href={link} target="_">
//                               <div className={styles["cirtified-card"]}>
//                                 <Image
//                                   src={awardImageUrl}
//                                   alt={awd?.image?.data?.attributes?.name || "Awards"}
//                                   height={100}
//                                   width={100}
//                                   quality={90}
//                                   loading="lazy"
//                                 />
//                               </div>
//                             </Link>
//                           ) : (
//                             <div className={styles["cirtified-card"]}>
//                               <Image
//                                 src={awardImageUrl}
//                                 alt={awd?.image?.data?.attributes?.name || "Awards"}
//                                 height={100}
//                                 width={100}
//                                 quality={90}
//                                 loading="lazy"
//                               />
//                             </div>
//                           )}
//                         </Col>
//                       </React.Fragment>
//                     );
//                   })}
//                 </Row>
//               </Col>
//             )}

//             <Col lg={3} md={3} sm={12} xs={12}>
//               <Row>
//                 {data?.ratings &&
//                   data?.ratings.map((rat, index) => (
//                     <Col lg={12} md={12} sm={6} xs={6} key={index}>
//                       <Link href={rat.link || "#"}>
//                         <div
//                           className={`${styles["cirtified-card"]} ${styles["google-card"]} ${styles[`card${index}`]}`}
//                         >
//                           <div className={styles["google-card-heading"]}>
//                             <p>
//                               <Image src="/images/home/base-star.svg" alt="star" height={20} width={20} />
//                               {rat.ratings}
//                             </p>
//                             <Image
//                               src={toWebp(`${baseUrl}${rat?.provider_image?.data?.attributes?.url}`)}
//                               alt={rat?.provider_image?.data?.attributes?.name || "rating"}
//                               height={40}
//                               width={150}
//                               loading="eager"
//                               layout="responsive"
//                             />
//                           </div>
//                         </div>
//                       </Link>
//                     </Col>
//                   ))}
//               </Row>
//             </Col>
//           </Row>
//         )}
//       </Container>
//     </section>
//   );
// };

// export default Award;


import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "next/image";
import Link from "next/link";
import { toWebp } from "../../../utils/common";
import styles from "./style.module.scss";

const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

const Award = (props) => {
  const inViewport = true;
  const data = props.data;
  const divRef = useRef(null);
  const [height, setHeight] = useState();

  useEffect(() => {
    const setView = () => {
      if (window?.innerWidth > 1400) {
        setHeight(825);
      } else {
        setHeight(588);
      }
    };
    setView();

    window.addEventListener("resize", setView);
    return () => {
      window.removeEventListener("resize", setView);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const imageUrl = baseUrl + data?.background?.data?.attributes?.url;
      divRef.current.style.backgroundImage = `url(${imageUrl})`;
    }
  }, [data]);

  return (
    <section ref={divRef} className={styles["award-new-section"]} style={{ minHeight: `${height}px` }}>
      <Container className={styles["container"]}>
        {inViewport && (
          <Row>
            {data?.awards && (
              <Col lg={9} md={9} sm={12} xs={12}>
                <Row>
                  {data?.awards.map((awd, i) => {
                    const awardImageUrl = toWebp(`${baseUrl}${awd?.image?.data?.attributes?.url}`);
                    const link = awd?.link; // Assume the backend provides a 'link' field for each award

                    return (
                      <React.Fragment key={i}>
                        {i === 4 && (
                          <Col lg={8} md={8} sm={6} xs={6} className={styles["ordersm-01"]}>
                            <div className={`${styles["cirtified-card"]} ${styles["award-card"]}`}>
                              <h3>Awards & Acolades</h3>
                            </div>
                          </Col>
                        )}
                        <Col lg={4} md={4} sm={6} xs={6}>
                          {link ? (
                            // Add `target="_blank"` to open in a new tab
                            <Link href={link} passHref target="_blank">
                              <div className={styles["cirtified-card"]}>
                                <Image
                                  src={awardImageUrl}
                                  alt={awd?.image?.data?.attributes?.name || "Awards"}
                                  height={100}
                                  width={100}
                                  quality={90}
                                  loading="lazy"
                                />
                              </div>
                            </Link>
                          ) : (
                            <div className={styles["cirtified-card"]}>
                              <Image
                                src={awardImageUrl}
                                alt={awd?.image?.data?.attributes?.name || "Awards"}
                                height={100}
                                width={100}
                                quality={90}
                                loading="lazy"
                              />
                            </div>
                          )}
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>
              </Col>
            )}

            <Col lg={3} md={3} sm={12} xs={12}>
              <Row>
                {data?.ratings &&
                  data?.ratings.map((rat, index) => (
                    <Col lg={12} md={12} sm={6} xs={6} key={index}>
                      <Link href={rat.link || "#"} passHref target="_blank">
                        <div
                          className={`${styles["cirtified-card"]} ${styles["google-card"]} ${styles[`card${index}`]}`}
                        >
                          <div className={styles["google-card-heading"]}>
                            <p>
                              <Image src="/images/home/base-star.svg" alt="star" height={20} width={20} />
                              {rat.ratings}
                            </p>
                            <Image
                              src={toWebp(`${baseUrl}${rat?.provider_image?.data?.attributes?.url}`)}
                              alt={rat?.provider_image?.data?.attributes?.name || "rating"}
                              height={40}
                              width={150}
                              loading="eager"
                              layout="responsive"
                            />
                          </div>
                        </div>
                      </Link>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default Award;
