import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import Image from "next/image";
import Link from "next/link";
import { decodeHTMLEntities, toWebp } from "../../../utils/common";
import styles from "./style.module.scss";

const url = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

const ContactCase = (props) => {
	const { data } = props;

	const articleData = data?.case_study || [];
	const processedArticles = articleData.map((article) => {
		// const formattedDate = article.date
		//     ? moment(article.date).format("MMMM Do, YYYY")
		//     : article.createdAt
		//     ? moment(article.createdAt).format("MMMM Do, YYYY")
		//     : article.updatedAt
		//     ? moment(article.updatedAt).format("MMMM Do, YYYY")
		//     : "Unknown Date";

		// const formattedTime = article.time
		//     ? moment(article.time, "HH:mm:ss").format("h:mm A")
		//     : null;

		const imageURL = `${url}${article.image?.data?.attributes?.url}`;
		const href = article?.button?.href || `/blog/${article.slug}`;

		return {
			...article,
			// formattedDate,
			// formattedTime,
			imageURL,
			href, // Add href dynamically
		};
	});

	return (
		<section
			className={styles["feature-new-section"]}
			style={{ minHeight: "858px" }}
		>
			<Container className={styles["container"]}>
				<Row>
					<Col lg={12}>
						<div className={styles["feature-heading-content"]}>
							<h2>{decodeHTMLEntities(data.title)}</h2>
						</div>
					</Col>

					{/* Render first article */}
					{processedArticles?.[0] && (
						<Col lg={5} md={6} sm={6}>
							<Link href={processedArticles[0]?.href}>
								<div className={styles["feature-card"]}>
									<Image
										src={toWebp(
											processedArticles[0]?.imageURL,
										)}
										alt="article"
										height={670}
										width={1448}
										quality={100}
									/>
									<div
										className={
											styles["feature-card-content"]
										}
									>
										<h3>
											{decodeHTMLEntities(
												processedArticles[0]?.title,
											)}
										</h3>
										{/* <ul>
											<li>{processedArticles[0]?.time || "N/A"}</li>
											<li>{processedArticles[0]?.formattedDate}</li>
										</ul> */}
										<ul>
											<li>
												{processedArticles[0]?.time ||
													"N/A"}
											</li>
											<li>
												{processedArticles[0]?.date ||
													"No Date Provided"}
											</li>
										</ul>
									</div>
								</div>
							</Link>
						</Col>
					)}

					{/* Render second article */}
					{processedArticles?.[1] && (
						<Col lg={4} md={6} sm={6}>
							<Link href={processedArticles[1]?.href}>
								<div className={styles["feature-card"]}>
									<Image
										src={toWebp(
											processedArticles[1]?.imageURL,
										)}
										alt="article"
										height={670}
										width={1448}
										quality={100}
									/>
									<div
										className={`${styles["feature-card-content"]} ${styles["feature-top-content"]}`}
									>
										<h3>
											{decodeHTMLEntities(
												processedArticles[1]?.title,
											)}
										</h3>
										{/* <ul>
											<li>{processedArticles[1]?.time || "N/A"}</li>
											<li>{processedArticles[1]?.formattedDate}</li>
										</ul> */}
										<ul>
											<li>
												{processedArticles[1]?.time ||
													"N/A"}
											</li>
											<li>
												{processedArticles[1]?.date ||
													"No Date Provided"}
											</li>
										</ul>
									</div>
								</div>
							</Link>
						</Col>
					)}

					{/* Render remaining articles */}
					<Col lg={3} md={12}>
						<Row>
							{processedArticles.slice(2).map((article) => (
								<Col lg={12} md={6} sm={6} key={article.id}>
									<Link href={article.href}>
										<div
											className={`${styles["feature-card"]} ${styles["feature-card-right"]}`}
										>
											<Image
												src={toWebp(article.imageURL)}
												alt="article"
												height={670}
												width={1448}
												quality={100}
											/>
											<div
												className={
													styles[
														"feature-card-content"
													]
												}
											>
												<h3>
													{decodeHTMLEntities(
														article.title,
													)}
												</h3>
											</div>
										</div>
									</Link>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ContactCase;
